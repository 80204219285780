import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Pagination, DatePicker, Select, Tooltip, Button } from 'antd';
import useAxios from 'axios-hooks';
import Cookies from 'js-cookie';
import { Checkbox } from 'antd';

const PaymentTable = ({ columns, dataSource, submitSuccessful }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState({
    agencyName: '',
    agencyId: '',
    startDate: new Date().toDateString(),
    onlyDate: 'false',
  });

  const [{ data, loading }, loadData] = useAxios(
    {
      url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchQuery=${filterValue.agencyName}&Created=${filterValue.startDate}&SpecifiedDateOnly=${filterValue.onlyDate}`,
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/json',
      },
      data: {},
    },
    { manual: true },
  );

  const handlePagination = (pagination) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  useEffect(() => {
    loadData({
      url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchQuery=${filterValue.agencyName}&Created=${filterValue.startDate}&SpecifiedDateOnly=${filterValue.onlyDate}`,
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/json',
      },
      data: {},
    }).then((response) => {
      setPagination(JSON.parse(response.headers.pagination));
    });
  }, [submitSuccessful,pageSize,pageNumber]);

  const onChange = (date, dateString) => {
    setPageNumber(1);
    setFilterValue({
      ...filterValue,
      startDate: dateString,
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
          }}
        >
          <div style={{ width: '100%', marginRight: '10px' }}>
            <DatePicker
              style={{
                width: '100%',
              }}
              onChange={onChange}
            />
          </div>
          <div style={{ width: '150%', marginTop: '5px' }}>
            <Checkbox
              value={filterValue.onlyDate}
              onChange={(e) => {
                setPageNumber(1);
                setFilterValue({
                  ...filterValue,
                  onlyDate: e.target.checked,
                });
              }}
            >
              Show specific date
            </Checkbox>
          </div>
        </div>
        <Tooltip title="search">
          <Button
            style={{marginLeft:'5px'}}
            onClick={loadData}
            type="primary"
            shape="circle" icon={<SearchOutlined />} />
        </Tooltip>
      </div>

      <Table columns={columns} loading={loading} dataSource={data}
             onChange={handlePagination}
             pagination={{
               pageSize: pageSize,
               current: pageNumber,
               total: pagination.totalItems,
               pageSizeOptions: ["10", "20", "30", "50"],
               showSizeChanger: true,
             }}
      />

    </>
  );
};

export default PaymentTable;
