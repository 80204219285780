import React, { useState } from 'react';
import { Card, Row, Col } from 'antd';
import { BASE_URL } from '../../constants';
import PaymentTable from '../../components/PaymentTable';
import Hyperlink from '../../components/Hyperlink';
import MakePayment from './components/MakePayment';
import ViewPayment from './components/ViewPayment';
import { toaster } from 'evergreen-ui';

const Payments = () => {
  const [makePaymentOpen, setMakePaymentOpen] = React.useState(false);
  const [submitSuccessful, setSubmitSuccessful] = React.useState(false);
  const [selectedPayment, setSelectedPayment] = React.useState(null);
  const [viewPaymentOpen, setViewPaymentOpen] = React.useState(false);
  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
    },
    /*{
      title: 'Value Book Name',
      dataIndex: 'valuebookName',
      key: 'valuebookName'
    },
    */{
      title: 'Payment Made By',
      dataIndex: 'agencyName',
      key: 'agencyName',
    },
    {
      title: 'Ref No.',
      dataIndex: 'paymentGatewayRef',
      key: 'paymentGatewayRef',
      render: (text) => text || 'N / A',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        if (status === 'Approved') {
          return <p style={{ color: 'green', fontSize: '13px' }}>{status}</p>;
        }

        if (status === 'Declined') {
          return <p style={{ color: '#E53E3E', fontSize: '13px' }}>{status}</p>;
        }

        return <p style={{ color: '#F6AD55', fontSize: '13px' }}>{status}</p>;
      },

    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text) => "GHc "+text.toLocaleString() || 'N / A',
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Hyperlink
            icon="eye"
            onClick={() => {
              setSelectedPayment(record);
              setViewPaymentOpen(true);
            }}
          >
            Manage Payment
            {record.name}
          </Hyperlink>
        </span>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title="Payments"
            extra={<Hyperlink onClick={() => setMakePaymentOpen(true)}>Purchase Value Book</Hyperlink>}
          >
            <PaymentTable
              dataSource={`${BASE_URL}/api/payments/agencyInvoices`}
              columns={columns}
              submitSuccessful={submitSuccessful}
            />
          </Card>
        </Col>
      </Row>


      {
        makePaymentOpen ?
          <MakePayment
            isShown={makePaymentOpen}
            onCloseComplete={() => {
              //window.location.reload();
              setMakePaymentOpen(false);
            }}
            onSubmitSuccessful={(data)=>{
              setSubmitSuccessful(data)
            }}
          />
          : ""
      }

      {
        viewPaymentOpen?
          <ViewPayment
            isShown={viewPaymentOpen}
            payment={selectedPayment}
            onCloseComplete={() => {
              setViewPaymentOpen(false);
            }}
            onSubmitSuccessful={(data)=>{
              setSubmitSuccessful(data)
            }}
          />
          :""
      }

    </>
  );
};

export default Payments;
