import React, { useState, useEffect } from 'react';
import { Row, Card, Table, Col, Spin } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import Hyperlink from './components/Hyperlink';
import ViewReceipt from './components/ViewReceipt';
import { BASE_URL } from './constants';

function Dashboard() {
  const header = {
    Authorization: 'Bearer ' + Cookies.get('token'),
    'Content-Type': 'application/json'
  };

  const agency= Cookies.get('agency');

  const dashboard = {
    topNAgency: axios.get(BASE_URL + '/api/reports/topNAgencyReceiptCount', {
      params: {},
      headers: header,
    }),
    weeklyTotals: axios.get(BASE_URL + '/api/reports/weeklyTotals', { headers: header }),
    /*recentReceipts: axios.get(BASE_URL + '/api/gcreceipts?SearchQuery='+JSON.parse(agency).name, {
      headers: header ,
    }),*/
    recentReceipts: axios.get(BASE_URL + '/api/gcreceipts/agencyGCReceipts', {
      headers: header,
      params:{},
      data:{},
    }),
    todaySummary: axios.get(BASE_URL + '/api/reports/receiptsGeneratedAndBalance', {
      headers: header,
    }),
    certificateSummary: axios.get(BASE_URL + '/api/reports/formsGeneratedAndBalance', {
      headers: header,
    }),
  };

  const [viewReceiptOpen, setViewReceiptOpen] = useState(false);
  const [selectedReceipt, selectReceipt] = useState({});
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    weeklyData: [],
    topNAgencyData: [],
    recentReceipts: [],
    todaySummary: {},
    certificateSummary: {},
  });

  const columns = [
    {
      title: 'Receipt No.',
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
    },
    {
      title: 'Date',
      dataIndex: 'receiptDate',
      key: 'receiptDate',
      render: text => <div>{new Date(text).toLocaleDateString()}</div>,
    },

    {
      title: 'Recipient Name',
      dataIndex: 'recipientName',
      key: 'recipientName',
    },
    {
      title: 'Recipient Email',
      dataIndex: 'recipientEmail',
      key: 'recipientEmail',
    },
    {
      title: 'Recipient Phone',
      dataIndex: 'recipientPhone',
      key: 'recipientPhone',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: amount => <span>GHc {Number(amount).toFixed(2)}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Hyperlink
            icon="eye"
            onClick={() => {
              selectReceipt(record);
              setViewReceiptOpen(true);
            }}
          >
            View Receipt
            {record.name}
          </Hyperlink>
        </span>
      ),
    },
  ];

  useEffect(() => {
    Promise.all([
      dashboard.topNAgency,
      dashboard.weeklyTotals,
      dashboard.recentReceipts,
      dashboard.todaySummary,
      dashboard.certificateSummary,
    ]).then(response => {
      /*console.log("we are here ",response);*/
      setDashboardData({
        topNAgencyData: response[0].data,
        weeklyData: response[1].data,
        recentReceipts: response[2].data,
        todaySummary: response[3].data,
        certificateSummary: response[4].data,
      });
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="example">
        <Row>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                marginTop: '150px',
              }}
            >
              <Spin
                size="large"
                tip="Loading receipts....."
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Today's Statistics</h3>
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  Receipts Generated
                </dt>
                <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                  {(dashboardData.todaySummary.receiptGenerated).toLocaleString()}
                </dd>
              </dl>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  Receipts Remaining
                </dt>
                <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                  {(dashboardData.todaySummary.remainingReceipt).toLocaleString()}
                </dd>
              </dl>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  Receipt Balance
                </dt>
                <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                  GHS {parseFloat(dashboardData.todaySummary.accountBalance.toFixed(2)).toLocaleString()}
                </dd>
              </dl>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  Certificate Balance
                </dt>
                <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                  GHS {parseFloat(dashboardData.certificateSummary.accountBalance.toFixed(2)).toLocaleString()}
                  {/*GHS 0.00*/}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col span={24}>
          <Card title="Recent Receipt Transactions" style={{ marginTop: '10px' }}>
            <Table columns={columns} dataSource={dashboardData.recentReceipts} pagination={false}/>
          </Card>
        </Col>
      </Row>

      <ViewReceipt
        isShown={viewReceiptOpen}
        receipt={selectedReceipt}
        onCloseComplete={() => setViewReceiptOpen()}
        width="500px"
      />
    </>
  );
}

export default Dashboard;
