import React from 'react';

function Button({ children, ...buttonProps }) {
  return (
    <button
      type="submit"
      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
      {...buttonProps}
    >
      {children}
    </button>
  );
}

export default Button;
