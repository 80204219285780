import React from 'react';
import { Alert, SideSheet } from 'evergreen-ui';
import { Col, Row } from 'antd';
import TextInput from '../../../components/TextInput';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../../constants';
import Cookies from 'js-cookie';
import { toaster } from 'evergreen-ui';
import Select from '../../../components/Select';
import Hyperlink from '../../../components/Hyperlink';
import { saveAs } from 'file-saver';

const initialState = [
    {
      totalAmount: 0,
      unit: 0,
      valuebookType: "",
      unitPrice: "",
    },
  ];


function MakePayment({ onCloseComplete, isShown , onSubmitSuccessful}) {
  const [formData, setFormData] = React.useState([
    {
      totalAmount: 0,
      unit: 0,
      valuebookType: "",
      unitPrice: "",
    }
  ]);

  const setPaymentLineQuantity = index => e => {
    let newArr = [...formData];
    newArr[index][e.target.name] = e.target.value;
    newArr[index]['totalAmount'] = e.target.value*newArr[index]['unitPrice'];
    setFormData(newArr);
  };

  const setPaymentLineValueBookType = index => e => {
    let newArr = [...formData];
    const val_book=valueBook_data.filter(function(valuebook) {
      return valuebook.vBtype === e.target.value;
    });
    newArr[index][e.target.name] = e.target.value;
    newArr[index]['unitPrice'] = val_book[0].unitPrice;
    newArr[index]['totalAmount'] = newArr[index]['unitPrice']*newArr[index]['unit'];
    setFormData(newArr);
  };

  const removeItem=(index)=>{
    let oldState = [...formData];
    let newState = oldState.slice(0, index).concat(oldState.slice(index + 1, oldState.length));
    setFormData(newState);
  }

  function addPaymentLine() {
    let old_state=formData;
    old_state.push({
      totalAmount: 0,
      unit: 0,
      valuebookType: "",
      unitPrice: "",
    });
    setFormData(old_state);
  }

  const [{ loading, data, error }, registerUser] = useAxios(
    {
      url: `${BASE_URL}/api/payments`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'post',
      responseType: 'blob',
      data: {
        "emailInvoice": false,
        "paymentLines": [
          ...formData
        ],
      },
    },
    { manual: true },
  );

  if (error){
    console.log(error)
  }

  const valueBook_datas =[
    {
      "id": 1,
      "vBtype": "GCR",
      "name": "Government of Ghana E-Receipt",
      "unitPrice": 1.00
    },
    {
      "id": 2,
      "vBtype": "Token",
      "name": "Token for Forms and Certificates",
      "unitPrice": 1.00
    }
  ];

  const [{ data:valueBook_data, loading:valueBook_loading, error:valueBook_error }] = useAxios({
    url: `${BASE_URL}/api/payments/valuebookLookup`,
    headers: {
      Authorization: `Bearer ${Cookies.get('token')}`,
      'content-type': 'application/json'
    },
    params:{

    },
    data:{

    }
  });

  if(!valueBook_loading){
    console.log("valueBook_data ",valueBook_error);
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ margin: '20px' }}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Create Invoice</h3>
          Complete the form below to create an invoice
        </div>
        <div style={{ marginTop: '40px' }}>
          {error ? (
            <Alert intent="danger">
              <p>{error.response.data}</p>
            </Alert>
          ) : null}
          <Hyperlink onClick={() => addPaymentLine()}>Add Value Book item</Hyperlink>
          <form
            className="mt-8"
            onSubmit={(e) => {
              e.preventDefault();
              registerUser().then((data) => {
                toaster.success('Payment order has been made successfully');
                setFormData(initialState);
                console.log(data);
                const blob = new Blob(
                  [data.data],
                  {type: 'application/pdf'});
                saveAs(blob, "Value book Invoice.pdf");
                //var file = data['response'];
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL);
                onSubmitSuccessful(data);
                setTimeout(() => {
                  onCloseComplete();
                }, 1000);
              });
            }}
          >

            {
              formData.map((paymentLine,index)=>{
                return <Row gutter={5}>
                  <Col span={8}>
                    {
                      index<1?
                        <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                          Value Book Type
                        </label>:""
                    }
                    <div className="mb-5">
                      <div className="rounded-md shadow-sm">
                        <Select
                          required
                          name="valuebookType"
                          onChange={setPaymentLineValueBookType(index)}
                        >
                          <option value="">Select</option>
                          {valueBook_data?
                            valueBook_data.map((valueBook,i)=>{
                              return <option value={valueBook.vBtype}>{valueBook.name}</option>
                            }):""
                          }
                        </Select>
                      </div>
                    </div>
                  </Col>

                  <Col span={4}>
                    {
                      index<1?
                        <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                          Price(Ghc)
                        </label>:""
                    }
                    <div className="rounded-md shadow-sm mb-5">
                      <TextInput
                        disabled
                        type="number"
                        value={paymentLine.unitPrice}
                      />
                    </div>
                  </Col>

                  <Col span={5}>
                    {
                      index < 1 ?
                        <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                          Quantity
                        </label>:''
                    }
                    <div className="rounded-md shadow-sm mb-5">
                      <TextInput
                        required
                        type="number"
                        name='unit'
                        min='1'
                        pattern="^\d*(\.\d{0,2})?$"
                        value={paymentLine.unit}
                        onChange={setPaymentLineQuantity(index)}
                      />
                    </div>
                  </Col>

                  <Col span={6}>
                    {
                      index < 1 ?
                        <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                          Total Amount
                        </label>:''
                    }
                    <div className="rounded-md shadow-sm mb-5">
                      <TextInput
                        required
                        value={parseFloat(paymentLine.totalAmount.toFixed(2)).toLocaleString()}
                        disabled
                      />
                    </div>
                  </Col>

                  <Col span={1}>
                    {
                      index < 1 ?
                        <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                          Action
                        </label>:''
                    }
                    <div className="rounded-md"  style={{ padding:'5px'}}>
                      <a onClick={(e)=>removeItem(index)} style={{ color:'red'}} >
                        X
                      </a>
                    </div>

                  </Col>
                </Row>
              })
            }
            <Row>
              <Col span={24}>
                <Button type="submit">{loading ? 'Saving...' : 'Generate Invoice'}</Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </SideSheet>
  );
}


export default MakePayment;
