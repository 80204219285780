import React from 'react';
import { SideSheet, toaster } from 'evergreen-ui';
import TextInput from '../../../components/TextInput';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../../constants';
import Cookies from 'js-cookie';
import Button from '../../../components/Button';
import { Spin,Row } from 'antd';
import { Table } from 'antd';
import fileDownload from "js-file-download";

function ViewPayment({ onCloseComplete, isShown, payment ,onSubmitSuccessful}) {
  const [getInvoiceFileLoading, setInvoiceFileLoading] = React.useState(false);
  const [paymentReference,setPaymentReference]=React.useState('');
  const url=payment?`${BASE_URL}/api/payments/agencyInvoices/${payment.id}`:"";
  const refUrl=payment?`${BASE_URL}/api/payments/${payment.id}/confirmPayment`:"";
  const [{ data, loading, error }] = useAxios({
      url: url,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
  });

  function getInvoiceFile() {
    (async () => {
      setInvoiceFileLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/api/payments/invoices/${payment.id}/invoiceFile`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('token')}`,
              'Content-Disposition': `attachment; filename=${payment.id}.pdf filename*=${payment.id}.pdf`,
            },
            method: 'get',
          },
        );

        const invoiceFile = await response.blob();

        fileDownload(invoiceFile, `CAGD Invoice ${payment.id}.pdf`);
      } catch (e) {
        toaster.danger('Failed to download invoice file');
      }
      setInvoiceFileLoading(false);
    })();
  }

  const [{ loading:setReferenceLoading, data:setReferenceData, error :setReferenceError}, setReference] = useAxios(
    {
      url: `${refUrl}/${paymentReference}`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'put',
      data: {
      },
    },
    { manual: true },
  );


  if(!loading){
    console.log(data);
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      {payment && !loading && data ? (
        <div style={{ padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Invoice/Payment Information</h3>
            </div>
          </div>
          <div className="mt-5 border-t border-gray-200 pt-5">
                <dl>
                  <div className=" sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm leading-5 font-medium text-gray-500">
                      Payment Reference
                    </dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      {data.paymentGatewayRef ||
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          /*console.log({
                            "emailInvoice": false,
                            "paymentLines": [
                              ...formData
                            ],
                          });*/
                          setReference().then((data) => {
                            toaster.success('Invoice reference successfully updated');
                            setPaymentReference("");
                            onSubmitSuccessful(data);
                            setTimeout(() => {
                              onCloseComplete();
                            }, 1000);
                          });
                        }}
                      >
                        <div>
                          <TextInput
                            required
                            type="text"
                            name='dataRefNumber'
                            onChange={(e)=>{
                              setPaymentReference(e.target.value)
                            }}
                            style={{marginBottom:'5px'}}
                          />
                          <Button type="submit">{setReferenceLoading ? 'Updating..' : 'Submit Reference'}</Button>
                        </div>
                      </form>
                      }
                    </dd>
                  </div>
                  <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Amount</dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      GHc {data.totalAmount?data.totalAmount.toFixed(2):""}
                    </dd>
                  </div>
                  <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Payment Status</dt>
                    <dd
                      className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                      style={{
                        color:
                          data.status === 'Approved'
                            ? 'green'
                            : data.status === 'Declined'
                            ? '#E53E3E'
                            : '#F6AD55',
                      }}
                    >
                      {data.status}
                    </dd>
                  </div>
                  <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Payment Verified?</dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      {data.dataVerified ? 'Yes' : 'No'}
                    </dd>
                  </div>
                  <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Payment Method</dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      {data.paymentMethod}
                    </dd>
                  </div>
                  <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Payment Made By</dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      {data.createdBy}
                    </dd>
                  </div>
                  <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Payment Updated By</dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      {data.updatedBy || 'N/A'}
                    </dd>
                  </div>

                  <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Created On</dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      {new Date(data.createdOn).toDateString()} at{' '}
                      {new Date(data.createdOn).toLocaleTimeString()}
                    </dd>
                  </div>
                  <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dt className="text-sm leading-5 font-medium text-gray-500">Updated On</dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                      {new Date(data.updatedOn).toDateString()} at{' '}
                      {new Date(data.updatedOn).toLocaleTimeString()}
                    </dd>
                  </div>
                </dl>
              </div>
              {
                data.paymentDetails?
                  <div>
                    <Row className="mt-2 border-t border-gray-200 pt-1">
                      {/*<h3 style={{textAlign:'center'}}>Payment Details</h3>*/}
                      <Table
                        pagination={false}
                        dataSource={data.paymentDetails}
                             columns={[{
                                title: 'Book value type',
                                dataIndex: 'valuebookType',
                                key: 'valuebookType',
                              },
                                {
                                  title: 'Book value name',
                                  dataIndex: 'valuebookName',
                                  key: 'valuebookName',
                                },
                                {
                                  title: 'Quantity',
                                  dataIndex: 'unit',
                                  key: 'unit',
                                  render: (text, record) => (
                                    <span>
                                      {record.unit.toLocaleString()}
                                    </span>
                                  ),
                                },
                                {
                                  title: 'Total Amount',
                                  dataIndex: 'totalAmount',
                                  key: 'totalAmount',
                                  render: (text, record) => (
                                    <span>
                                      GHc {record.totalAmount.toLocaleString()}
                                    </span>
                                  ),
                                }]}
                      />
                    </Row>
                  </div>
                  :
                  ""
              }
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '60px',
            }}
          >
            <Button
              style={{ marginRight: '20px' }}
              onClick={getInvoiceFile}
              disabled={getInvoiceFileLoading}
            >
              {getInvoiceFileLoading ? 'Downloading..' : 'Download as PDF'}
            </Button>
          </div>
        </div>
      ) :
        <div style={{textAlign:'center',marginTop:'50px'}}>
          <h4>Loading payment details....</h4>
          <Spin spinning="true"/>
        </div>
      }
    </SideSheet>
  );
}

export default ViewPayment;
