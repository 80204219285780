import React, { useState } from 'react';
import { Card, Row, Col } from 'antd';
import { BASE_URL } from '../../constants';
import Hyperlink from '../../components/Hyperlink';
import IssueCertificate from './IssueCertificate';
import CertificateTable from '../../components/CertificateTable';
import ViewCertificate from '../../components/ViewCertificate';

const Certificate = () => {
  const [viewReceiptOpen, setViewReceiptOpen] = useState(false);
  const [selectedReceipt, selectReceipt] = useState({});
  const [openIssueReceipt, setOpenIssueReceipt] = useState(false);

  const columns = [
    {
      title: 'Certificate No.',
      dataIndex: 'token',
      key: 'token',
    },
    {
      title: 'Agency Ref. No.',
      dataIndex: 'agencyUniqueReference',
      key: 'agencyUniqueReference',
      render: (text) => (!text ? 'N / A' : text),
    },
    {
      title: 'Date',
      dataIndex: 'tokenDate',
      key: 'tokenDate',
      render: (text) => <div>{new Date(text).toLocaleDateString()}</div>,
    },

    {
      title: 'Recipient Name',
      dataIndex: 'recipientName',
      key: 'recipientName',
    },
    {
      title: 'Recipient Email',
      dataIndex: 'recipientEmail',
      key: 'recipientEmail',
      render: (text) => (text ? text : 'N / A'),
    },
    {
      title: 'Recipient Phone',
      dataIndex: 'recipientPhone',
      key: 'recipientPhone',
      render: (text) => (text ? text : 'N / A'),
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (amount) => <span>GHc {Number(amount).toFixed(2)}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Hyperlink
            icon="eye"
            onClick={() => {
              selectReceipt(record);
              setViewReceiptOpen(true);
            }}
          >
            View Certificate
            {record.name}
          </Hyperlink>
        </span>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title="Generated Certificates"
          >
            <CertificateTable
              dataSource={`${BASE_URL}/api/forms/agencyCertificates`}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>

      <ViewCertificate
        isShown={viewReceiptOpen}
        receipt={selectedReceipt}
        onCloseComplete={() => setViewReceiptOpen(false)}
        width="500px"
      />

      <IssueCertificate
        isShown={openIssueReceipt}
        onCloseComplete={() => {
          setOpenIssueReceipt(false);
          window.location.reload();
        }}
      />
    </>
  );
};

export default Certificate;
