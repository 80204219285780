import React, { useState } from 'react';
import { Card, Row, Col } from 'antd';
import { BASE_URL } from '../../constants';
import FormTable from '../../components/FormTable';
import Hyperlink from '../../components/Hyperlink';
import IssueForm from './IssueForm';
import ViewForm from '../../components/ViewForm';

const Form = () => {
  const [viewFormOpen, setViewFormOpen] = useState(false);
  const [selectedForm, selectForm] = useState({});
  const [openIssueForm, setOpenIssueForm] = useState(false);

  const columns = [
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
    },
    {
      title: 'Agency Ref. No.',
      dataIndex: 'agencyUniqueReference',
      key: 'agencyUniqueReference',
      render: (text) => (!text ? 'N / A' : text),
    },
    {
      title: 'Date',
      dataIndex: 'tokenDate',
      key: 'tokenDate',
      render: text => <div>{new Date(text).toLocaleDateString()}</div>,
    },

    {
      title: 'Recipient Name',
      dataIndex: 'recipientName',
      key: 'recipientName',
    },
    {
      title: 'Recipient Email',
      dataIndex: 'recipientEmail',
      key: 'recipientEmail',
    },
    {
      title: 'Recipient Phone',
      dataIndex: 'recipientPhone',
      key: 'recipientPhone',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: amount => <span>GHc {Number(amount).toFixed(2)}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Hyperlink
            icon="eye"
            onClick={() => {
              selectForm(record);
              setViewFormOpen(true);
            }}
          >
            View
            {record.name}
          </Hyperlink>
        </span>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title="Generated Tokens"
            /*extra={<Hyperlink onClick={() => setOpenIssueForm(true)}>Issue Form</Hyperlink>}*/
          >
            <FormTable
              dataSource={`${BASE_URL}/api/forms/agencyForms`}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>

      <ViewForm
        isShown={viewFormOpen}
        token={selectedForm}
        onCloseComplete={() => setViewFormOpen(false)}
        width="500px"
      />

      <IssueForm
        isShown={openIssueForm}
        onCloseComplete={() => {
          setOpenIssueForm(false);
          window.location.reload();
        }}
      />
    </>
  );
};

export default Form;
