import React from 'react';
import { Row, Col, Card } from 'antd';
import Button from '../../components/Button';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../constants';
import Cookies from 'universal-cookie';
import { Alert, toaster } from 'evergreen-ui';
import LoadingState from '../../components/LoadingState';
import TextInput from '../../components/TextInput';

const AgencySettings = () => {
  const cookies = new Cookies();
  const userProfile = cookies.get('user');

  const isAuthorizedToChangeAPI = userProfile.role.includes('Admin');

  const [{ loading: APIKeyChangeLoading, error: APIKeyChangeError }, changeAPIKey] = useAxios(
    {
      url: `${BASE_URL}/api/agencies/generateAPIKey`,
      headers: { Authorization: `Bearer ${cookies.get('token')}` },
      method: 'post',
    },
    { manual: true },
  );

  const [{ data, loading, error }] = useAxios({
    url: `${BASE_URL}​/api/agencies/apiKey`,
    headers: { Authorization: `Bearer ${cookies.get('token')}` },
  });

  if (loading) {
    return <LoadingState message={'Loading Agency Settings'} />;
  }

  if (error) {
    toaster.danger('Failed to change API Key', {
      description: error.response ? error.response.data : '',
    });
  }
  return (
    <>
      <Card title="Agency Settings">
        <h5>API Key</h5>
        <div>
          <TextInput value={data.key} disabled />

          {isAuthorizedToChangeAPI ? (
            <Button
              style={{ marginTop: '20px' }}
              onClick={() => {
                const answer = window.prompt(
                  "Are you sure you want to change API Key. This will affect all other clients using the existing API key. If you are sure, type in 'YES'",
                );

                if (answer === 'YES') {
                  changeAPIKey().then(() => {
                    window.location.reload();
                  });
                }
              }}
            >
              {APIKeyChangeLoading ? 'Loading...' : 'Change API Key'}
            </Button>
          ) : null}
        </div>
      </Card>
    </>
  );
};

export default AgencySettings;
