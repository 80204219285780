import React, { useState } from 'react';
import { Card, Row, Col, Select } from 'antd';
import Notification from '../../components/Notification';
import useAxios from 'axios-hooks';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../constants';
import { MINISTRIES } from '../../constants';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { Alert } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const RegisterAgency = () => {
  const [name, setName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [ministry, setMinistry] = useState('');
  const [email, setEmail] = useState('');
  const [prefix, setPrefix] = useState('');
  const [agencies, setAgencies] = useState('');
  const [minUnitThreshold, setMinUnitThreshold] = useState();
  const [receiptLength, setReceiptLength] = useState();

  const history = useHistory();

  const [
    { loading: registerAgencyLoading, data: registerAgencyData, error },
    registerAgency,
  ] = useAxios(
    {
      url: `${BASE_URL}/api/agencies`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'post',
      data: {
        prefix,
        name,
        ministry,
        emailAddress: email,
        contactNumber,
        minUnitThreshold,
        receiptLength,
      },
    },
    { manual: true },
  );

  return (
    <>
      <Row>
        <Col span={24}>
          <Card title="Register Agency" style={{ marginRight: '20px' }}>
            {error ? (
              <Alert intent="danger">
                <p>{error.response.data}</p>
              </Alert>
            ) : null}
            <div className="px-4 pt-4 pb-4 mb-4 flex flex-col my-2">
              <Row gutter={16}>
                <Col span={6}>
                  <div className="-mx-3 md:flex mb-4">
                    <div className="md:w-full px-3 mb-6 md:mb-0">
                      <label
                        className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Prefix
                      </label>
                      <TextInput
                        type="text"
                        placeholder="EG: NIA"
                        value={prefix}
                        onChange={e => setPrefix(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={18}>
                  <div className="-mx-3 md:flex mb-4">
                    <div className="md:w-full px-3 mb-6 md:mb-0">
                      <label
                        className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Name of Agency
                      </label>
                      <TextInput
                        type="text"
                        required
                        placeholder="Example Agency"
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="-mx-3 md:flex mb-4">
                    <div className="md:w-full px-3 mb-6 md:mb-0">
                      <label
                        className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Email
                      </label>
                      <TextInput
                        required
                        type="email"
                        placeholder="contact@agency.gov.gh"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="md:w-full px-3 mb-6 md:mb-0">
                    <label
                      className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Contact Number
                    </label>
                    <TextInput
                      required
                      id="grid-first-name"
                      type="tel"
                      placeholder="+2333064732"
                      value={contactNumber}
                      onChange={e => setContactNumber(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div className="-mx-3 md:flex mb-4">
                    <div className="md:w-full px-3 mb-6 md:mb-0">
                      <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                        Minimum Unit Threshold
                      </label>
                      <TextInput
                        required
                        type="number"
                        value={minUnitThreshold}
                        onChange={e => setMinUnitThreshold(Number(e.target.value))}
                      />
                    </div>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="md:w-full px-3 mb-6 md:mb-0">
                    <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                      Receipt Length
                    </label>
                    <TextInput
                      required
                      type="number"
                      value={receiptLength}
                      onChange={e => setReceiptLength(Number(e.target.value))}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div className="-mx-3 md:flex mb-4">
                    <div className="md:w-full px-3 mb-6 md:mb-0">
                      <label
                        className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Ministry
                      </label>
                      <Select
                        id="grid-first-name"
                        style={{ width: '100%' }}
                        value={ministry}
                        onChange={value => setMinistry(value)}
                      >
                        <Option value=""></Option>
                        {MINISTRIES.map(item => {
                          return <Option value={item}>{item}</Option>;
                        })}
                      </Select>
                    </div>
                  </div>
                </Col>
              </Row>

              <Button
                onClick={() => {
                  registerAgency().then(response => {
                    setAgencies(agencies.concat(response.data));
                    setContactNumber('');
                    setEmail('');
                    setMinistry('');
                    setPrefix('');
                    setName('');
                    Notification({
                      type: 'success',
                      message: 'Agency has been registered successfully. Please set up new user',
                    });
                    history.push(`/core/agency-management/${response.data.id}`);
                  });
                }}
              >
                {registerAgencyLoading ? 'Loading...' : 'Register Agency'}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RegisterAgency;
