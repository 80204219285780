import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useHistory, Redirect } from 'react-router-dom';
import { toaster } from 'evergreen-ui';
import useAxios from 'axios-hooks';

// images
import coatOfArms from '../assets/images/coatofarms.svg';

// components
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import Hyperlink from '../components/Hyperlink';

// constants
import { LOGIN_URL } from '../constants';

const cookies = new Cookies();

function SignIn() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  const [{ loading }, login] = useAxios(
    {
      url: LOGIN_URL,
      method: 'post',
      data: {
        email,
        password,
      },
    },
    { manual: true },
  );

  if (redirect) {
    return <Redirect to="/core" />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <img className="mx-auto h-12 w-auto" src={coatOfArms} alt="Coat of Arms Logo" />
          <h2 className="mt-6 text-center text-3xl">CAGD Atlantis MDA Portal</h2>
          <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">Sign In</p>
        </div>
        <form className="mt-8" onSubmit={onSubmitLogin}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <TextInput
              aria-label="Email address"
              name="email"
              type="email"
              required
              placeholder="Email address"
              onChange={e => setEmail(e.target.value)}
            />
            <TextInput
              aria-label="Password"
              name="password"
              type="password"
              required
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div className="mt-6">
            <Button disabled={loading}>{loading ? 'Loading...' : 'Sign In'}</Button>
          </div>

          <div className="mt-6 align-center">
            <Hyperlink to="/reset-password">I forgot my password</Hyperlink>
          </div>
        </form>
      </div>
    </div>
  );

  async function onSubmitLogin(e) {
    e.preventDefault();
    try {
      const response = await login();
      cookies.set('user', response.data.user);
      cookies.set('agency', response.data.agency);
      cookies.set('token', response.data.token);
      setRedirect(true);
    } catch (e) {
      toaster.danger('Login failed', {
        description: 'The email and / or password entered is incorrect',
      });
    }
  }
}

export default SignIn;
