import React, { useState } from 'react';
import './index.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Container from './components/Container';
import Dashboard from './Dashboard';
import Agencies from './pages/Agencies/Agency';
import RegisterAgency from './pages/Agencies/RegisterAgency';
import Footer from './components/Footer';
import SignIn from './pages/SignIn';
import Receipt from './pages/Receipts/Receipt';
import Certificate from './pages/Certificates/Certificate';
import Form from './pages/Forms/Form';
import Report from './pages/Reports/GeneralReports';
import Payments from './pages/Payments/Payments';
import AgencyReport from './pages/Agencies/AgencyReport';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings/Settings';
import AgencySettings from './pages/AgencySettings/AgencySettings';
import { useIdleTimer } from 'react-idle-timer'
import Cookies from 'js-cookie';

const Core = () => {
  const [logout, setLogout] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);

  const handleOnIdle = event => {
    Cookies.remove('token');
    window.location.href='/';
  };

  const handleOnActive = event => {
    console.log('user is active', event);
    console.log('time remaining', getRemainingTime())
  };

  const handleOnAction = (e) => {
    console.log('user did something', e)
  };

  const {
    getRemainingTime,
    getLastActiveTime
  } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });


  if (logout) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className="font-sans font-light text-sm bg-gray-200 flex flex-col min-h-screen w-full">
        <div id="header">
          <Header />
          <NavBar />
        </div>
        <Container>
          <Switch>
            <Route exact path="/core" component={Dashboard} />
            <Route exact path="/core/agency-management" component={Agencies} />
            <Route exact path="/core/agency-management/create-agency" component={RegisterAgency} />
            <Route exact path="/core/agency-management/:id" component={AgencyReport} />
            <Route exact path="/core/receipt-management" component={Receipt} />
            <Route exact path="/core/certificate-management" component={Certificate} />
            <Route exact path="/core/form-management" component={Form} />
            <Route exact path="/core/payments" component={Payments} />
            <Route exact path="/core/reports" component={Report} />
            <Route exact path="/core/settings" component={Settings} />
            <Route exact path="/core/agency-settings" component={AgencySettings} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </div>
  );
};

function App() {
  return (
    <>
      <Switch>
        <Route path="/core" component={Core} />
      </Switch>
      <Switch>
        <div className=" flex flex-col">
          <Route exact path="/" component={SignIn} />
          <Route exact path="/reset-password" component={ResetPassword} />
        </div>
      </Switch>
    </>
  );
}

export default App;
