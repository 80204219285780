import React from 'react';
import { Card, Tag, Row, Col } from 'antd';
import { BASE_URL } from '../../constants';
import Hyperlink from '../../components/Hyperlink';
import AgencyTable from '../../components/AgencyTable';

const columns = [
  {
    title: 'Prefix',
    dataIndex: 'prefix',
    key: 'prefix',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <Hyperlink to={'/core/agency-management/' + record.id}>{text}</Hyperlink>
    ),
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Ministry',
    dataIndex: 'ministry',
    key: 'ministry',
    sorter: (a, b) => a.ministry.localeCompare(b.ministry),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Email Address',
    dataIndex: 'emailAddress',
    key: 'emailAddress',
  },
  {
    title: 'Contact Number',
    key: 'contactNumber',
    dataIndex: 'contactNumber',
    render: contactNumber => (
      <span>
        <Tag color="green">{contactNumber}</Tag>
      </span>
    ),
  },
];

const Agencies = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title="Registered Agencies"
            extra={<Hyperlink to="/core/agency-management/create-agency">Create Agency</Hyperlink>}
          >
            <AgencyTable columns={columns} dataSource={`${BASE_URL}/api/agencies`} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Agencies;
