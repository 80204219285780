import React from 'react';
import { Link } from 'react-router-dom';

function Hyperlink({ children, ...linkProps }) {
  return (
    <Link className="text-green-500 hover:text-green-800" {...linkProps}>
      {children}
    </Link>
  );
}

export default Hyperlink;
