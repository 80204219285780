import React, { useEffect, useState } from 'react';
import { SideSheet, toaster } from 'evergreen-ui';
import { BASE_URL } from '../constants';
import Cookies from 'js-cookie';
import fileDownload from 'js-file-download';
import Button from './Button';

import useAxios from 'axios-hooks';
import { Spin } from 'antd';

function ViewReceipt({ onCloseComplete, isShown, receipt }) {
  const [getReceiptFileLoading, setReceiptFileLoading] = React.useState(false);
  const [loadedReceiptData, setLoadedReceiptData] = React.useState({
    amount: 0,
  });

  function getReceiptFile() {
    (async () => {
      setReceiptFileLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/api/gcreceipts/${receipt.receiptNumber}/gcreceiptFile`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('token')}`,
              'Content-Disposition': `attachment; filename=${receipt.receiptNumber}.pdf filename*=${receipt.receiptNumber}.pdf`,
            },
            method: 'get',
          },
        );
        const receiptFile = await response.blob();
        fileDownload(receiptFile, `${receipt.receiptNumber}.pdf`);
      } catch (e) {
        toaster.danger('Failed to download receipt file');
      }
      setReceiptFileLoading(false);
    })();
  }

  const [{ loading: receiptEmailLoading }, resendReceiptEmail] = useAxios(
    {
      url: `${BASE_URL}/api/gcreceipts/${receipt.id}/emailReceipt`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'post',
    },
    { manual: true },
  );

  const [{ loading, error, data }, loadReceiptData] = useAxios(
    {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
    },
    { manual: true },
  );

  useEffect(() => {
    if (receipt && receipt.id) {
      loadReceiptData({
        url: `${BASE_URL}/api/gcreceipts/${receipt.id}`,
      }).then(response => {
        setLoadedReceiptData(response.data);
      });
    }
  }, [receipt]);

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      {receipt && receipt.amount ? (
        <div style={{ padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Receipt Information</h3>
            </div>
            {/*<Spin spinning={loading} />*/}
          </div>
          <Spin spinning={loading}>
            <div className="mt-5 border-t border-gray-200 pt-5">
            <dl>
              <div className=" sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm leading-5 font-medium text-gray-500">Receipt Number</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {loadedReceiptData.receiptNumber || receipt.receiptNumber}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  Receipt Agency Reference
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {loadedReceiptData.agencyUniqueReference || receipt.agencyUniqueReference}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Received by</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {loadedReceiptData.receivedBy || receipt.receivedBy}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Receipt description</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {loadedReceiptData.receiptDescription || receipt.receiptDescription}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Amount</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  GHc {loadedReceiptData.amount.toFixed(2) || receipt.amount.toFixed(2)}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Recipient Name</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {loadedReceiptData.recipientName || receipt.recipientName}
                </dd>
              </div>

              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Recipient Phone</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {loadedReceiptData.recipientPhone || receipt.recipientPhone || 'N / A'}
                </dd>
              </div>

              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Recipient Email</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {loadedReceiptData.recipientEmail || receipt.recipientEmail || 'N / A'}
                </dd>
              </div>

              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  Receipt Issuance Date
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {`${new Date(
                    loadedReceiptData.receiptDate || receipt.receiptDate,
                  ).toLocaleDateString()} at ${new Date(
                    loadedReceiptData.receiptDate || receipt.receiptDate,
                  )
                    .toLocaleTimeString()
                    .replace(/(.*)\D\d+/, '$1')}` || 'N / A'}
                </dd>
              </div>

              {!loadedReceiptData.additionalFields ? null : (
                <React.Fragment>
                  {Object.keys(loadedReceiptData.additionalFields).map(field => {
                    return (
                      <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <dt
                          className="text-sm leading-5 font-medium text-gray-500"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {field}
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                          {loadedReceiptData.additionalFields[field]}
                        </dd>
                      </div>
                    );
                  })}
                </React.Fragment>
              )}
            </dl>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '60px',
              }}
            >
              <Button
                style={{ marginRight: '20px' }}
                onClick={getReceiptFile}
                disabled={getReceiptFileLoading}
              >
                {getReceiptFileLoading ? 'Downloading..' : 'Download as PDF'}
              </Button>
            </div>
          </div>
          </Spin>
        </div>
      ) : null}
    </SideSheet>
  );
}

export default ViewReceipt;
