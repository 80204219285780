import React, { useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../constants';
import Cookies from 'js-cookie';
import { Alert, toaster } from 'evergreen-ui';
import LoadingState from '../../components/LoadingState';
import { useHistory } from 'react-router-dom';
import CreateUser from './components/CreateUser';
import Hyperlink from '../../components/Hyperlink';
import EditUser from './components/EditUser';

const Settings = () => {
  /*const cookies = new Cookies();*/
  //console.log(Cookies.get('user'));
  const userProfile = JSON.parse(Cookies.get('user'));
  console.log("userProfile",userProfile);
  let isAuthorizedToManageUsers =false;
  if(userProfile){
    isAuthorizedToManageUsers =
      userProfile.role.includes('Admin') /*|| userProfile.role.includes('Manager')*/;
  }

  const history = useHistory();
  const [password, setPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [addUserShowing, setAddUSerShowing] = React.useState(false);
  const [editUserShowing, setEditUSerShowing] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState();
  const [seconds, setSeconds] = React.useState(3);

  const [{ data:passwordData, loading: passwordChangeLoading, error: passwordChangeError }, changePassword] = useAxios(
    {
      url: `${BASE_URL}/api/users/changePassword`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'post',
      data: {
        currentPassword: oldPassword,
        newPassword: password,
      },
    },
    { manual: true },
  );

  const [{ data, loading, error },getUsers] = useAxios({
    url: `${BASE_URL}​/api/users/agencyUsers`,
    headers: { Authorization: `Bearer ${Cookies.get('token')}` },
  });


  useEffect(() => {
    getUsers().then(response => {
      console.log(response)
    });
  }, []);

  async function onSubmitPasswordChange(e) {
    e.preventDefault();
    try {
      const response = await changePassword().then(() => {
        toaster.success('Success', {
          description:
            'Password has been changed successfully. Logging you out in 3 seconds',
        });
        /*setTimeout(() => {
          Cookies.remove('token');
          history.push('/');
        }, 3000);*/
      });
    } catch (e) {
      toaster.danger('Failed to change password', {
        description: 'Credentials provided are not correct. Please enter the right password',
      });
    }
  }

  if(passwordData===""){
    console.log("passwordData ",passwordData);
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if(seconds===0){
      Cookies.remove('token');
      window.location.href='/';
    }
  }

  if (loading) {
    return <LoadingState message={'Loading Settings'} />;
  }
  return (
    <>
      {passwordData === "" ?
        <h2 style={{fontSize:"24px", textAlign: 'center' }}>
          Logging out in {seconds} seconds
        </h2> :
        <div>
          <Card title="Settings">
            <div style={{ marginBottom: '50px' }}>
              <h5>Change Password</h5>
              {passwordChangeError && passwordChangeError.response ? (
                <Alert intent="danger">
                  <p>{passwordChangeError.response.data}</p>
                </Alert>
              ) : null}

              <form className="mt-8" onSubmit={onSubmitPasswordChange}>

                <Row style={{ marginBottom: 20, marginTop: 20 }} gutter={10}>
                  <Col span={8}>
                    <div className="-mx-3 md:flex mb-4">
                      <div className="md:w-full px-3 mb-6 md:mb-0">
                        <label
                          className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Old Password
                        </label>
                        <TextInput
                          type="password"
                          value={oldPassword}
                          required
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col span={8}>
                    <div className="-mx-3 md:flex mb-4">
                      <div className="md:w-full px-3 mb-6 md:mb-0">
                        <label
                          className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          New Password
                        </label>
                        <input
                          type="password"
                          value={password}
                          required
                          minLength={5}
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col span={8}>
                    <div className="-mx-3 md:flex mb-4">
                      <div className="md:w-full px-3 mb-6 md:mb-0">
                        <label
                          className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Confirm New Password
                        </label>
                        <TextInput
                          type="password"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                {
                  password !== confirmPassword ?
                    <Row style={{ marginBottom: 20, marginTop: 20 }} gutter={10}>
                      <Col span={8}>
                        <div className="-mx-3 md:flex mb-4">
                          <div className="md:w-full px-3 mb-6 md:mb-0">
                            <label
                              className="block capitalize tracking-wide text-xs font-bold mb-2"
                              style={{ color: 'red' }}
                              htmlFor="grid-first-name"
                            >
                              New password and confirm password do not match
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row> : ""
                }


                <Row>
                  <Col span={8}>
                    <Button
                      disabled={password !== confirmPassword}
                    >
                      {passwordChangeLoading ? 'Loading...' : 'Change Password'}
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>

            {isAuthorizedToManageUsers ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                    alignItems: 'center',
                  }}
                >
                  <h5>System Users</h5>

                  <div>
                    <Button onClick={() => setAddUSerShowing(true)}>Add User</Button>
                  </div>
                </div>

                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                  <ul>
                    {
                      data.map((user) => {
                        return (
                          <li>
                            <div className="flex items-center px-4 py-4 sm:px-6">
                              <div className="min-w-0 flex-1 flex items-center">
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                  <div>
                                    <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                                      {user.fullName} | {user.roles.toString()}
                                    </div>
                                    <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                      <svg
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <span className="truncate">{user.email}</span>
                                    </div>
                                  </div>

                                  <div className="hidden md:block">
                                    <div>
                                      <div className="text-sm leading-5 text-gray-900">
                                        Phone - {user.phoneNumber}
                                      </div>
                                      <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                        Created - {new Date(user.lastActive).toLocaleDateString()} at{' '}
                                        {new Date(user.lastActive).toLocaleTimeString()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <Hyperlink
                                  onClick={() => {
                                    setSelectedUser(user);
                                    setEditUSerShowing(true);
                                  }}
                                >
                                  Edit User
                                </Hyperlink>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              </div>
            ) : null}
          </Card>

          <CreateUser
            isShown={addUserShowing}
            onCloseComplete={() => {
              /*window.location.reload();*/
              getUsers();

              setAddUSerShowing(false);
            }}
          />

          {editUserShowing ? (
            <EditUser
              user={selectedUser}
              isShown={editUserShowing}
              onCloseComplete={() => {
                /*window.location.reload();*/
                getUsers();
                setEditUSerShowing(false);
              }}
            />
          ) : null}
        </div>
      }
    </>
  );
};

export default Settings;
