import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col } from 'antd';
import Cookies from 'universal-cookie';
import { BASE_URL } from '../../constants/index';
import ReportTable from '../../components/GeneralReportTable';
import { EyeOutlined } from '@ant-design/icons';
import ViewReport from './components/ViewReport'
import useAxios from 'axios-hooks';


const GeneralReports = () => {
  const [viewReportOpen, setViewReportOpen] = useState(false);
  const [selectedReport, selectReport] = useState({});
  const columns = [
    {
      title: 'Report Name',
      dataIndex: 'reportName',
    },
    {
      title: 'Report Description',
      dataIndex: 'reportDesc',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record)=> (
        <span>
        <Button
          onClick={() => {
            selectReport(record);
            setViewReportOpen(true);
          }}
          type='primary'
          icon={<EyeOutlined/> } size='small'> View</Button>
      </span>
      ),
    },
  ];
  const cookie = new Cookies();

  const [{ data:agencyData, loading:agencyLoading }, loadAgencyData] = useAxios(
    {
      url: `${BASE_URL}/api/system/agencylookup`,
      headers: { Authorization: `Bearer ${cookie.get('token')}` },
    },
    { manual: true },
  );

  useEffect(() => {
    console.log("loading data");
    loadAgencyData();
  }, []);

  if (agencyData){
    console.log('here',agencyData)
  }

  console.log(agencyLoading,agencyData);
  const user = cookie.get('user');
  const isAuthorizedToCreateAgency = user.role.includes('Admin') || user.role.includes('Manager')
  return (
    <>
      {
        viewReportOpen?
          <ViewReport
            isShown={viewReportOpen}
            report={selectedReport}
            agencyData={agencyData}
            onCloseComplete={() => {
              setViewReportOpen(false)
            }}
            width="500px"
          />
          :""
      }


      <Row>
        <Col span={24}>
          <Card
            title="List of reports"
          >
            <ReportTable columns={columns} dataSource={`${BASE_URL}/api/reports/allReports`} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default GeneralReports;
