import React from 'react';
import FeatherIcon from 'feather-icons-react';
import Cookies from 'universal-cookie';
import { Menu, Popover, Button, Position } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import Hyperlink from './Hyperlink';

const Header = () => {
  const cookies = new Cookies();
  const userProfile = cookies.get('user');
  const agency = cookies.get('agency');
  const history = useHistory();
  return (
    <div className="bg-green-900">
      <div className="w-5/6 mx-auto px-4">
        <div className="flex items-center md:justify-between py-4">
          <div className="flex flex-row items-center justify-center">
            <div className="mr-3 md:mr-3">
              <Hyperlink to="/core">
                <a rel="home">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/5/59/Coat_of_arms_of_Ghana.svg"
                    alt="logo"
                    style={{
                      height: '50px',
                      width: '80px',
                      paddingRight: '15px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  />
                </a>
              </Hyperlink>
            </div>
            <div>
              <div className="w-1/2 md:w-auto text-white text-1xl font-medium">{agency.name}</div>
              <div className="w-1/2 md:w-auto text-white font-light">{agency.ministry}</div>
            </div>
          </div>
          <div className="w-1/4 md:hidden">
            <FeatherIcon className="fill-current text-white h-8 w-8" icon="menu" />
          </div>
          <div className="w-1/4 md:w-auto md:flex text-right">
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item>
                      <Hyperlink to="/core/settings">Account Settings</Hyperlink>
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() => {
                        cookies.remove('token');
                        cookies.remove('user');
                        history.push('/');
                      }}
                      intent="danger"
                    >
                      Logout
                    </Menu.Item>
                  </Menu.Group>
                  <Menu.Divider />
                </Menu>
              }
            >
              <Hyperlink
                height={40}
                appearance="minimal"
                style={{ display: 'flex', alignItems: 'cneter' }}
              >
                <p style={{ color: '#ffffff' }}>{userProfile.fullName}</p>
                <span style={{ marginLeft: '10px' }}>
                  <FeatherIcon icon="chevron-down" style={{ color: '#ffffff' }} />
                </span>
              </Hyperlink>
            </Popover>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Header;
