import React from 'react';
import { SideSheet } from 'evergreen-ui';
import Button from '../../../components/Button';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../../constants';
import Cookies from 'js-cookie';
import { toaster } from 'evergreen-ui';
import { Select } from 'antd';

function EditUser({ onCloseComplete, isShown, user }) {
  const [formData, setFormData] = React.useState({
    roles: user.roles,
  });

  const [{ loading, data, error }, editUserRoles] = useAxios(
    {
      url: `${BASE_URL}/api/users/editRoles/${user.email}`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'post',
      data: {
        roleNames: formData.roles,
      },
    },
    { manual: true },
  );

  const [{ loading: deleteLoading, error: deleteError }, removeUser] = useAxios(
    {
      url: `${BASE_URL}/api/users/${user.email}`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'delete',
      data: {
        roleNames: formData.roles,
      },
    },
    { manual: true },
  );

  if (data) {
    toaster.success('Successfully updated', {
      description: 'User details successfully updated',
    });
    onCloseComplete();
  }

  if (error) {
    toaster.danger('Error', {
      description: 'Failed to update user role',
    });
  }

  if (deleteError) {
    toaster.danger('Error', {
      description: 'Failed to remove user',
    });
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ margin: '20px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Edit {user.fullName}'s account
          </h3>

          <div>
            <Button
              style={{ backgroundColor: 'red' }}
              onClick={async () => {
                await removeUser().then(() => {
                  onCloseComplete();
                });
              }}
            >
              {deleteLoading ? 'Loading..' : 'Remove User'}
            </Button>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <div style={{ marginTop: '20px' }}>
            <h5>Edit User Roles</h5>
          </div>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            required
            placeholder="Please select roles"
            value={formData.roles}
            onChange={(value) =>
              setFormData({
                ...formData,
                roles: value,
              })
            }
          >
            <Select.Option value="Admin">Admin</Select.Option>
            <Select.Option value="Manager">Manager</Select.Option>
            <Select.Option value="Agent">Agent</Select.Option>
          </Select>
        </div>

        <div style={{ marginTop: '40px' }}>
          <Button
            onClick={async () => {
              await editUserRoles().then(() => {
                onCloseComplete();
              });
            }}
          >
            {loading ? 'Loading...' : 'Update User Roles'}
          </Button>
        </div>
      </div>
    </SideSheet>
  );
}

export default EditUser;
