import React,{useState,useEffect} from 'react';
import { SideSheet, toaster } from 'evergreen-ui';
import { BASE_URL } from '../../../constants';
import { CSVLink } from "react-csv";

import useAxios from 'axios-hooks';
import { Modal, Button as b2, Table, Row, Col, Input, Select } from 'antd';
import Button from '../../../components/Button';
import Cookies from 'js-cookie';
import { JsonToExcel } from 'react-json-excel';

/*const fields = {
  id: 'ID',
  receiptNumber: 'Receipt Number',
  parentReceiptNumber: 'Parent Receipt Number',
  agencyUniqueReference: 'Agency Unique Reference Number',
  receiptDate: 'Date',
  recipientName: 'Recipient Name',
  recipientEmail: 'Recipient Email',
  recipientPhone: 'Recipient Phone Number',
  amount: 'Amount',
  status: 'Status',
  agencyName: 'Name of Agency',
};*/

const routes={
  1:"/api/reports/FetchAllAgencyReceipts",
  3:"/api/reports/FetchAllAgencyInvoices",
  2:"/api/reports/FetchAllAgencyTokens",
  4:"/api/reports/FetchAgencyReceiptsSummary",
  5:"/api/reports/FetchAgencyTokensSummary",
}

function ViewReport({ onCloseComplete, isShown, report,agencyData }) {
  const [startDate,setStartDate]=useState("");
  const [endDate,setEndDate]=useState("");
  const [period,setPeriod]=useState("");
  const [agency,setAgency]=useState("");
  const [showModal,setShowModal]=useState(false);
  const [fields,setFields]=useState("");
  const [csvData,setCSVData]=useState([]);

  const [{ loading,data }, generateReport] = useAxios(
    {
      url: BASE_URL+routes[report.id],
      method: 'post',
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      data: {
        startDate,endDate,period,agencyId:agency
      },
    },
    { manual: true },
  );


  if(!report){
    report={}
  }
  else{
    console.log(report)
  }

  async function onSubmitLogin(e) {
    e.preventDefault();
    console.log(startDate,endDate,period,agency);
    try {
      const response = await generateReport().then((responsea)=>{
        console.log('res',responsea);

        const fields=[];
        Object.keys(responsea.data[0]).map((e)=>{
          //console.log(e);
          if (e!=='id'){
            if(e!=="receiptType") {
              if (e !== "agencyName") {
                if((e.toUpperCase()).includes("DATE")||(e.toUpperCase())===("CREATEDON")){
                  fields.push({
                    title: e==="displayDate"?"DATE":(e.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")).toUpperCase(),
                    label: e==="displayDate"?"DATE":(e.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")).toUpperCase(),
                    dataIndex: e,
                    key: e,
                    render: (text) => e==="displayDate"?text: formatDate(new Date(text)),
                  });
                }
                else {
                  if((e.toUpperCase()).includes("AMOUNT")){
                    fields.push({
                      title: (e.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")).toUpperCase(),
                      label: (e.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")).toUpperCase(),
                      dataIndex: e,
                      key: e,
                      render: (text) => String(Number(parseFloat(text).toFixed(2)).toLocaleString('en', {
                          minimumFractionDigits: 2,
                        }),
                      ),
                      align:"right"
                    });
                  }
                  else {
                    fields.push({
                      title: (e.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")).toUpperCase(),
                      label: (e.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")).toUpperCase(),
                      dataIndex: e,
                      key: e,
                      render: (text) => (!text ?e==='count'?0: 'N / A' : text),
                    });
                  }
                }
              }
            }
          }
          else {
            if((report.reportName.toUpperCase()).includes("INVOICE")){
              fields.push({
                title: "INVOICE NO.",
                label: "INVOICE NO.",
                dataIndex: e,
                key: e,
              });
            }
          }
        });
        const csv_data = responsea.data.map(row => (
          {
            ...row,
            createdOn: formatDate(new Date(row.createdOn)),
            receiptDate: formatDate(new Date(row.receiptDate)),
            tokenDate: formatDate(new Date(row.tokenDate)),
          })
        );

        setCSVData(csv_data);
        setFields(fields);
        if (responsea.data.length<100){
          setShowModal(true);
        }
      });
    } catch (e) {
      toaster.danger('Failed to generate report', {
        description: 'We failed to generate the report you specified for. Please try again',
      });
    }
  }


  function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.toDateString()+ "  " + strTime;
  }


  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ padding: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{report.reportName}</h3>
          </div>
        </div>
        <div className="mt-5 border-t border-gray-200 pt-5">
          <dl>
            <h5>{report.reportDesc}</h5>
            <h4 style={{fontWeight:'900', marginTop:'25px'}}>{report.criteriaDesc}</h4>
            <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            </div>

            <form onSubmit={onSubmitLogin}>
              <Row gutter={6}>
                {
                  report.startDate?
                    <Col span={24} style={{marginTop:'10px'}}>
                      <label htmlFor="startDate">Start Date</label>
                      <Input
                        style={{
                          width: '100%',
                        }}
                        type={'date'}
                        onChange={value =>
                          setStartDate(value.target.value)
                        }
                        required={report.startDateValidate}
                        placeholder='Start Date'
                      />
                    </Col>:""
                }
                {
                  report.startDate ?
                    <Col span={24} style={{marginTop:'10px'}}>
                      <label htmlFor="endDate">End Date</label>
                      <Input
                        style={{
                          width: '100%',
                        }}
                        type={'date'}
                        onChange={value =>
                          setEndDate(value.target.value)
                        }
                        required={report.endDateValidate}
                        placeholder='End Date'
                        /*onChange={onChange}*/
                      />
                    </Col> : ""
                }
              </Row>
              {
                report.period?
                  <Row  gutter={6} style={{marginTop:'10px'}}>
                    <Col span={24}>
                      <label htmlFor="period">Period</label>
                      <select
                        onChange={value =>
                          setPeriod(value.target.value)
                        }
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                        required={report.periodValidate}
                        placeholder="Period"
                      >
                        <option value="">Select</option>
                        <option value="DD">Daily</option>
                        <option value="WK">Weekly</option>
                        <option value="MM">Monthly</option>
                      </select>
                    </Col>
                  </Row>
                  :
                  ""
              }
              {
                report.agency?
                  <Row  gutter={6} style={{marginTop:'10px'}}>
                    <Col span={24}>
                      <label htmlFor="period">Agency</label>
                      <Select
                        style={{ width: '100%' }}
                        onChange={value =>
                          setAgency(value)
                        }
                        required={report.agency}
                        placeholder="Agency"
                      >
                        <Select.Option value="">Select</Select.Option>
                        {
                          agencyData?
                            agencyData.map((data)=>{
                              return <Select.Option value={data.id}>{data.name}</Select.Option>
                            })
                            :""
                        }
                      </Select>
                    </Col>
                  </Row>
                  :
                  ""
              }
              <Row  gutter={6} style={{marginTop:'10px'}}>
                <Button type='submit' block>
                  {
                    loading?
                      "Generating report...."
                      :
                      "Generate Report"
                  }
                </Button>
              </Row>
            </form>
          </dl>

          <div
            style={{
              marginTop: '60px',
            }}
          >
            {
              data?
                data.length>0?
                  <Row gutter={6}>
                    <Button
                      style={{ backgroundColor: 'purple' }}
                      onClick={()=>{
                        setShowModal(true)
                      }}
                    >
                      Preview report
                    </Button>
                    <Modal
                      width={'100'}
                      title={report.reportName.toUpperCase()+" REPORT"}
                      visible={showModal}
                      onCancel={()=>{setShowModal(false)}}
                      footer={[
                        <div style={{height:'30px'}}>
                          <Button style={{width:'10%',float:'right'}} key="back" onClick={()=>{setShowModal(false)}}>
                            Close
                          </Button>
                        </div>
                      ]}
                    >
                      <Col span={'12'}>
                        <CSVLink
                          data={csvData}
                          headers={fields}
                          filename={`${report.reportName + '_'}${new Date()+ '.csv'}`}
                        >
                          <Button
                            style={{ backgroundColor: 'blue' }}
                          >
                            Download report in excel
                          </Button>
                        </CSVLink>
                      </Col>
                      <Table dataSource={data} columns={fields} pagination={false} />
                    </Modal>
                  </Row>
                  :
                  "No data was returned"
                :
                ""
            }
          </div>
        </div>

      </div>
    </SideSheet>
  );
}

export default ViewReport;
