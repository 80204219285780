import React from 'react';
import { SideSheet, toaster } from 'evergreen-ui';
import { BASE_URL } from '../constants';
import Cookies from 'js-cookie';
import fileDownload from 'js-file-download';

import useAxios from 'axios-hooks';
import { Spin } from 'antd';

function ViewForm({ onCloseComplete, isShown, token }) {
  const [getFormFileLoading, setFormFileLoading] = React.useState(false);

  function getFormFile() {
    (async () => {
      setFormFileLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/api/gcreceipts/${token.tokenNumber}/gcreceiptFile`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('token')}`,
              'Content-Disposition': `attachment; filename=${token.tokenNumber}.pdf filename*=${token.tokenNumber}.pdf`,
            },
            method: 'get',
          },
        );

        const receiptFile = await response.blob();
        fileDownload(receiptFile, `${token.tokenNumber}.pdf`);
      } catch (e) {
        toaster.danger('Failed to download receipt file');
      }
      setFormFileLoading(false);
    })();
  }

  const [{ loading: receiptEmailLoading }, resendFormEmail] = useAxios(
    {
      url: `${BASE_URL}/api/gcreceipts/${token.id}/emailForm`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'post',
    },
    {
      manual: true
    },
  );

  if(token){
    console.log("token ",token);
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      {token && token.amount ? (
        <div style={{ padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Token Information</h3>
            </div>
          </div>
          <div className="mt-5 border-t border-gray-200 pt-5">
            <dl>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Token</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {token.token}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Value Book Name</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {token.valuebookName}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  Agency Reference
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {token.agencyUniqueReference}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Amount</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  GHc {token.amount.toFixed(2)}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Recipient Name</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {token.recipientName}
                </dd>
              </div>

              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Recipient Phone</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {token.recipientPhone || 'N / A'}
                </dd>
              </div>

              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Recipient Email</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {token.recipientEmail || 'N / A'}
                </dd>
              </div>
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Issued By</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {token.issuedBy || 'N / A'}
                </dd>
              </div>

              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  Token Issuance Date
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {`${new Date(
                    token.tokenDate || token.tokenDate,
                  ).toLocaleDateString()} at ${new Date(
                    token.tokenDate || token.tokenDate,
                  )
                    .toLocaleTimeString()
                    .replace(/(.*)\D\d+/, '$1')}` || 'N / A'}
                </dd>
              </div>
            </dl>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '60px',
              }}
            >
              {/*<Button
                style={{ marginRight: '20px' }}
                onClick={getFormFile}
                disabled={getFormFileLoading}
              >
                {getFormFileLoading ? 'Downloading..' : 'Download as PDF'}
              </Button>*/}
              {/*
           This resend email button has been axed
           <Button
                disabled={tokenEmailLoading}
                onClick={() =>
                  resendFormEmail().catch(() => {
                    toaster.danger('Error', {
                      description:
                        'Failed to send e-token. Please try again later or contact administrator if this issue persists',
                    });
                  })
                }
              >
                {tokenEmailLoading ? 'Resending Email...' : 'Resend Email'}
              </Button>*/}
            </div>
          </div>
        </div>
      ) :<Spin spinning={true}></Spin>
      }
    </SideSheet>
  );
}

export default ViewForm;
