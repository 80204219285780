import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'antd';
import useAxios from 'axios-hooks';
import Cookies from 'js-cookie';
import { Input } from 'antd';

const AgencyTable = ({ columns, dataSource }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState('');

  const [{ data, loading, error, response }, loadData] = useAxios(
    {
      url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
    },
    { manual: true },
  );

  useEffect(() => {
    if (filterValue) {
      loadData({
        url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchQuery=${filterValue}`,
        headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      });
    }

    loadData().then(response => {
      setPagination(JSON.parse(response.headers.pagination));
    });
  }, [filterValue, pageNumber, pageSize]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <Input
          value={filterValue}
          placeholder="Type a keyword"
          style={{ width: '30%' }}
          onChange={e => setFilterValue(e.target.value)}
        />
      </div>

      <Table columns={columns} loading={loading} dataSource={data} pagination={false} />
      <>
        <div
          style={{
            textAlign: 'right',
            marginTop: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            defaultCurrent={pageNumber}
            total={pagination.totalItems}
            onChange={pageNumber => {
              setPageNumber(pageNumber);
            }}
            pageSizeOptions={[10, 20, 30, 40, 50]}
            onShowSizeChange={(pageNumber, pageSize) => {
              setPageSize(pageSize);
            }}
            disabled={loading}
          />
        </div>
      </>
    </>
  );
};

export default AgencyTable;
